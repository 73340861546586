<template>
<div class="auth">
    <v-app app>
        <router-view></router-view>
    </v-app>
</div>
</template>

<script>
export default {
    name: "AuthLayout"
}
</script>

<style scoped>
.auth{
    background: #FCFDFF!important;
    height: 100vh;
}
</style>
